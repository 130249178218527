/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  backBtn: 'Back to overview',
  teaserText: 'Fusion is our answer to the market need of independence. Be flexible with all your product sources. With Fusion it doesn’t matter if you rely on Peakwork, Amadeus or anything else. You even can mix things up.',
  btnText: 'Check it out',
  valueTeaserTitle: 'Profit from a solid, flexible & affordable platform foundation',
  valueTeaserContent: 'The TravelSandbox® is designed to be your quickstart foundation to guarantee a short time to market process. If you want to, it’s of course possible to extend the base with custom features and general modifications to get the perfect solution for your business. And to be sustainable and flexible it doesn’t matter on which product or content sources you rely.',
  videoTeaserTitle: 'Fusion at a glance',
  valueTeaserBtnText: 'Learn more about the TravelSandbox®',
  valueBoxTitle1: 'Multiple product sources',
  valueBoxTitle2: 'Aggregate & unify',
  valueBoxTitle3: 'Connect to Web-/apps',
  descriptionTeaserTitle: 'A system build for flexibility',
  descriptionTeaserContent: 'We have developed a sophisticated set of products that allows us to react flexibly to all market requirements. Technology from a single source, created on the basis of more than a decade of experience. ',
  descriptionTeaserBtn: 'Get in touch now',
  descriptionTeaserSubTitle1: 'Multiple product sources',
  descriptionTeaserContent1: 'With Fusion we made it possible to connect product sources of any type. Enrich your product portfolio, Increase price combativity and be more flexible by using multiple providers. Mix & match products from different providers.',
  descriptionTeaserSubTitle2: 'Multiple content sources',
  descriptionTeaserContent2: 'With mightyC® you are able to aggregate and normalize content from any source. Use several third party sources like giata or tripAdvisor and combine them with your own one. Manage all data and distribute it to every necessary channel.',
  descriptionTeaserSubTitle3: 'Multi-brand web platform',
  descriptionTeaserContent3: 'We designed the TravelSandbox® with flexibility in mind. This does not stop at the high degree of customizability of the modern UI and best practice UX on all devices. Also you can extend and adjust the platform to fit your business needs. Conquer new markets and distrbution channels with advanced whitelabel configuration, theming and extension possibilites.'
})
