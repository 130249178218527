// @flow
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import messages from './FusionPage.lang'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'

// components
import { Container, Row, Col } from '../../../components/basic/Grid'
import ProductTeaser2 from '../ProductTeaser2'
import ValueBox from '../ValueBox'
import Products from '../../Products'
import DescriptionTeaser from '../DescriptionTeaser'
import ValueTeaser from '../ValueTeaser'

// images
import FusionLogo from '../../../images/logo/fusion.svg'
import logoTsbo from '../../../images/logo/travelsandbox.svg'
import fusionInfographic from '../../../images/products/fusion/infographic.svg'
import systemInfographicSrc from '../../../images/products/fusion/infographic-system.svg'

// styles
import baseStyles from './FusionPage.scss'

/* eslint-disable max-len */
const valueDesktop = (
  <StaticImage
    src="../../../images/products/mightyc/mightyc-teaser3-desktop.jpg"
    alt=""
    objectFit="cover"
    className="imageCover"
  />
)
const systemInfographic = <img src={systemInfographicSrc} alt="" className="image" />
const bgDesktop = (
  <StaticImage src="../../../images/products/fusion/background.jpg" alt="" objectFit="cover" className="imageCover" />
)
const teaser1Desktop = (
  <StaticImage src="../../../images/products/fusion/fusion-screen.png" alt="" objectFit="contain" className="image" />
)
/* eslint-enable max-len */

type Props = {
  ...StyleProps
}

const productValues1 = ['fusionValue1', 'fusionValue2', 'fusionValue3', 'fusionValue4']

const productValues2 = ['fusionValue1a', 'fusionValue2a', 'fusionValue3a', 'fusionValue4a']

const productValues3 = ['fusionValue1b', 'fusionValue2b', 'fusionValue3b', 'fusionValue4b']

const FusionPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <ProductTeaser2
        logo={FusionLogo}
        backBtn={formatMessage(messages.backBtn)}
        teaserText={formatMessage(messages.teaserText)}
        btnText={formatMessage(messages.btnText)}
        imageDesktop={teaser1Desktop}
        bgImageDesktop={bgDesktop}
        hiddenPageTitle="Fusion"
      />
      <Container fluid>
        <Row>
          <Col md="6" lg="4">
            <ValueBox
              vertical
              productValues1={productValues1}
              productValues2={productValues2}
              productValues3={productValues3}
              icon1="icon-pen-and-monitor"
              icon2="icon-lightbulb"
              icon3="icon-complex"
              title1={formatMessage(messages.valueBoxTitle1)}
              title2={formatMessage(messages.valueBoxTitle2)}
              title3={formatMessage(messages.valueBoxTitle3)}
            />
          </Col>
          <Col md="6" lg="8" className={styles.fusionInfographicContainer}>
            <img src={fusionInfographic} className={styles.fusionInfographic} alt="fusion infographic" />
          </Col>
        </Row>
      </Container>
      <DescriptionTeaser
        className={styles.descriptionTeaser}
        title={formatMessage(messages.descriptionTeaserTitle)}
        contentText={formatMessage(messages.descriptionTeaserContent)}
        btnText={formatMessage(messages.descriptionTeaserBtn)}
        btnLink="/contact/"
        imageDesktop={systemInfographic}
      >
        <div>
          <h3 className={styles.descriptionTeaserSubtitle}>{formatMessage(messages.descriptionTeaserSubTitle1)}</h3>
          <p className={styles.descriptionTeaserText}>{formatMessage(messages.descriptionTeaserContent1)}</p>

          <h3 className={styles.descriptionTeaserSubtitle}>{formatMessage(messages.descriptionTeaserSubTitle2)}</h3>
          <p className={styles.descriptionTeaserText}>{formatMessage(messages.descriptionTeaserContent2)}</p>

          <h3 className={styles.descriptionTeaserSubtitle}>{formatMessage(messages.descriptionTeaserSubTitle3)}</h3>
          <p className={styles.descriptionTeaserText}>{formatMessage(messages.descriptionTeaserContent3)}</p>
        </div>
      </DescriptionTeaser>
      <ValueTeaser
        className={styles.valueTeaser}
        title={formatMessage(messages.valueTeaserTitle)}
        contentText={formatMessage(messages.valueTeaserContent)}
        btnText={formatMessage(messages.valueTeaserBtnText)}
        mobileLogo={logoTsbo}
        desktopLogo={logoTsbo}
        imageDesktop={valueDesktop}
        btnLink="/products/travelsandbox/"
      />
      <Products type="fusion" />
    </div>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(FusionPage)
