import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../../components/Layout/index'
import Header from '../../modules/Header'
import Footer from '../../modules/Footer'
import FusionPage from '../../components/ProductPage/FusionPage'
import SEO from '../../components/seo'
import pageTitle from '../../messages/pageTitles.lang'
import metaDescription from '../../messages/pageMetaDescriptions.lang'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(pageTitle.TitleFusion)}
        description={formatMessage(metaDescription.fusionDescription)}
        lang={locale}
      />
      <FusionPage />
    </Layout>
  )
}

export default IndexPage
